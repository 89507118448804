import React, { useState } from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby-theme-material-ui"

import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import { makeStyles } from "@material-ui/core/styles"

import PhoneIcon from "../images/icons/phone.svg"
import MenuIcon from "../images/icons/menu.svg"

import Img from "gatsby-image"

const Navbar = ({ logo, company, phone, location }) => {
  const [openMenu, setOpenMenu] = useState(null)
  const classes = useStyles()

  const loctionsPage = location.pathname.match(/location/g) ? true : false

  const handleClick = event => {
    setOpenMenu(event.currentTarget)
  }

  const handleClose = () => {
    setOpenMenu(null)
  }

  return (
    <nav className={classes.navbarContainer}>
      {/* <nav className={classes.navbarContainer} id="top"> */}
      <div className={classes.navbarMobile}>
        <Button
          aria-label="menu"
          onClick={handleClick}
          className={classes.navbarMobileBtn}
        >
          <img alt="hamburger icon" src={MenuIcon} width="24px" height="24px" />
        </Button>
        <Menu
          id="mobile-menu"
          anchorEl={openMenu}
          keepMounted
          open={Boolean(openMenu)}
          onClose={handleClose}
        >
          <Link
            to={loctionsPage ? `${company}/#menu` : "#menu"}
            className={classes.navbarMobileLink}
          >
            <MenuItem onClick={handleClose}>Menu</MenuItem>
          </Link>

          <Link
            to={loctionsPage ? `${company}/#contact-info` : "#contact-info"}
            className={classes.navbarMobileLink}
          >
            <MenuItem onClick={handleClose}>Contact Us</MenuItem>
          </Link>

          <Link
            to={loctionsPage ? `${company}/#delivery` : "#delivery"}
            className={classes.navbarMobileLink}
          >
            <MenuItem onClick={handleClose}>Delivery</MenuItem>
          </Link>

          <Link
            to={company === "" ? "/locations" : "/boomboomchicken/locations"}
            className={classes.navbarMobileLink}
          >
            <MenuItem onClick={handleClose}>Locations</MenuItem>
          </Link>
        </Menu>
      </div>

      <Link
        color="textPrimary"
        // to={`/${company}`}
        to={company === "" ? "/" : "/boomboomchicken"}
        className={classes.navbarLogoContainer}
      >
        <Img fluid={logo.logo.fluid} className={classes.logo} alt={logo.name} />
      </Link>

      <div className={classes.navbarDesk}>
        <Link
          color="textPrimary"
          to={loctionsPage ? `${company}/#menu` : "#menu"}
          className={classes.navbarDeskLink}
        >
          Menu
        </Link>

        <Link
          color="textPrimary"
          to={loctionsPage ? `${company}/#contact-info` : "#contact-info"}
          className={`${classes.navbarItem} ${classes.navbarDeskLink}`}
        >
          contact info
        </Link>

        <Link
          color="textPrimary"
          to={loctionsPage ? `${company}/#delivery` : "#delivery"}
          className={`${classes.navbarItem} ${classes.navbarDeskLink}`}
        >
          Delivery
        </Link>

        <Link
          color="textPrimary"
          to={company === "" ? "/locations" : "/boomboomchicken/locations"}
          className={`${classes.navbarItem} ${classes.navbarDeskLink}`}
        >
          Locations
        </Link>
      </div>
      <div className={classes.navbarMobile}>
        <a href={`tel:${phone.replace(/[^0-9]/g, "")}`}>
          <img alt="phone icon" src={PhoneIcon} width="24px" height="24px" />
        </a>
      </div>
    </nav>
  )
}

export default Navbar

Navbar.propTypes = {
  logo: PropTypes.object.isRequired,
  company: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}

const useStyles = makeStyles(() => ({
  navbarContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "10px 18px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",

    "@media (max-width: 731px)": {
      display: "flex",
      padding: "1rem 1.5rem 1rem 1.5rem",
      flexDirection: "row",
    },
  },

  navbarLogoContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "1rem",
    "@media (max-width: 731px)": {
      paddingBottom: "0rem",
    },
  },

  logo: {
    width: "9rem",
    "&:hover": {
      filter: "brightness(87.5%)",
    },
    "@media (max-width: 731px)": {
      width: "5rem",
    },
  },

  navbarDesk: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 731px)": {
      display: "none",
    },
  },

  navbarItem: {
    marginLeft: 30,
  },

  navbarDeskLink: {
    fontWeight: 450,
    fontSize: "1rem",
    color: "black",
    cursor: "pointer",
    textTransform: "uppercase",
    alignItem: "center",
    borderBottom: "4px solid transparent",
    "&:focus, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
    "&:hover": {
      borderBottom: "4px solid red",
    },
  },

  navbarMobile: {
    display: "none",
    "@media (max-width: 731px)": {
      display: "inherit",
      lineHeight: 0,
      margin: 0,
      padding: 0,
    },
  },
  navbarMobileLink: {
    color: "black",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
  },
  navbarMobileBtn: {
    padding: 0,
    margin: 0,
    minWidth: "16px",
  },
}))
