import React from "react"

import scrollTo from "gatsby-plugin-smoothscroll"

import Typography from "@material-ui/core/Typography"

import TopArrowIcon from "../images/icons/arrow.svg"
import { makeStyles } from "@material-ui/core/styles"

const Footer = () => {
  const classes = useStyles()

  const today = new Date()
  const year = today.getFullYear()

  const handleScrollToTop = () => {
    scrollTo("#top")
  }

  return (
    <footer className={classes.footerContainer}>
      <div className={classes.top}>
        <button
          className={classes.link}
          onClick={handleScrollToTop}
          aria-label="scroll to top"
        >
          <img alt="top icon" src={TopArrowIcon} width="20px" height="20px" />
          <div className={classes.text}>Top</div>
        </button>
      </div>

      <div className={classes.copyright}>
        <Typography variant="caption">
          Copyright &copy; {year} <br />
          Boom Chicken & Boom Boom Chicken
        </Typography>
      </div>
    </footer>
  )
}

export default Footer

const useStyles = makeStyles(() => ({
  footerContainer: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    // width: "100vw",
    width: "100%",
    flexDirection: "column",
  },

  top: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "4px 30px 0px 30px",
  },

  link: {
    display: "flex",
    color: "black",
    textDecoration: "none !important",
    flexDirection: "column",
    alignItems: "center",
    "&:hover": {
      opacity: 0.5,
      cursor: "pointer",
    },
  },

  text: {
    paddingLeft: "4px",
    fontFamily: "Montserrat,sans-serif",
  },

  copyright: {
    paddingBottom: " 14px",
    fontSize: "14px",
    textAlign: "center",
  },
}))
