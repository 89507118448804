import React from "react"
import PropTypes from "prop-types"
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"
import "../styles/reset.css"

const theme = createMuiTheme({
  palette: {
    primary: red,
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
})

const Layout = ({ children }) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>{children}</div>
    </ThemeProvider>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 255,
    position: "relative",
    paddingBottom: "106px",
    minHeight: "100vh",
  },
}))
